<template>
  <div>
    <autogestion-Paso-Dos class="landscape" v-if="!(showQrDialog || showPdfDialog)"></autogestion-Paso-Dos>
    <autogestion-Paso-Tres class="landscape" v-if="showQrDialog || showPdfDialog"></autogestion-Paso-Tres>  
    <v-row>
    <v-col cols="12" style="height: 50px;"></v-col>
  </v-row>
    <div v-show="(!showDateDialog)">
      <v-container fluid style="padding-left: 100px;padding-right: 100px;">
        <v-row no-gutters>
          <v-col md="10" xl="6" offset-xl="2">
            <h3 class="ml-1 mb-2 colorCustom2--text font-weight-bold">{{ subTitleTabla() }}</h3>
          </v-col>
          <v-col md="2" class="px-0 mx-0" xl="3" offset-xl="2">
            <botonSelTodos v-if="!checkSelecionarTodo" @seleccion="clickCheckSelTodo"></botonSelTodos>
            <botonDesSelTodos v-if="checkSelecionarTodo" @seleccion="clickCheckSelTodo"></botonDesSelTodos>
          </v-col>
        </v-row>
        <div class="tablas">
          <!-- INICIO TOTAL A ABONAR -->
           <v-row no-gutters>
             <v-col md="12" xl="8" offset-xl="2">
               <v-simple-table>
                   <tbody>
                     <tr class="titulosTotales">
                       <td>
                         <span class="white--text" style="padding-left:28px"> TOTAL A ABONAR</span> 
                       </td>
                       <td align="right" style="padding-right:105px"> 
                         <template v-if="registros.descuento !== 0 && totalVencidas === totalVencidasSeleccionadas">
                           <span class="sinDescuento white--text">$ {{ $formatNumber(totalSeleccionada) }}</span> 
                           <span class="white--text">$ {{ $formatNumber(totalAbonar) }} </span>
                         </template>
                         <template v-else>
                           <span class="white--text">$ {{ $formatNumber(totalAbonar) }}</span> 
                         </template>
                       </td>
                     </tr>
                   </tbody>  
               </v-simple-table>
             </v-col>
           </v-row> 
           <!-- FIN TOTAL A ABONAR -->
           <v-row no-gutters>
             <v-col md="12" xl="8" offset-xl="2">
               <v-simple-table>
                   <colgroup>
                       <col span="1" style="width:500px;">
                       <col span="1" style="width:150px">
                       <col span="1" style="width:250px">
                       <col span="1" style="width:90px">
                   </colgroup>
                   <tbody>
                     <tr class="bodyCuotas ">
                       <td>
                         <span class="white--text pl-8">A VENCER</span>                      
                       </td>
                       <td>
                         <span>SUBTOTAL</span>  
                       </td>
                       <td align="right"> 
                           <span>$ {{ $formatNumber(totalAVencer) }}</span> 
                       </td>
                       <td @click.stop="clickCheckAVencer">                    
                           <v-checkbox
                             :class="!registros.cuotasAVencer.length ? 'ocultar' : ''"
                             class="centerCheckbox"
                             :ripple="false"
                             color="primary"
                             v-model="checkAVencer"                        
                             disabled                    
                           ></v-checkbox>  
                       </td>
                     </tr>
                   </tbody>
                 </v-simple-table>
               </v-col>
             </v-row>
             <v-row no-gutters>
               <v-col md="12" xl="8" offset-xl="2">
                 <v-simple-table :height="heightAVencer">
                   <colgroup>
                     <col span="1" style="width:500px">
                     <col span="1" style="width:150px">
                     <col span="1" style="width:250px">
                     <col span="1" style="width:90px">
                   </colgroup>              
                   <tbody name="fade" is="transition-group">
                     <tr
                       v-for="(item,k) in registros.cuotasAVencer"
                       :key="k"
                       @click.stop="clickRow(item,'aVencer',k)" 
                       class=""
                       :class="item.check ? 'colorCheck' : ''"                                     
                     >
                       <td colspan="2">
                         <span class="ml-8">
                           {{ item.periodo }}
                           <template v-if="tributo == 'COMERCIOS'">
                             / {{ item.tasa}}                        
                           </template>    
                         </span>
                       </td>
                       <td align="right">
                         <span>$ {{ $formatNumber(item.total) }}</span>                       
                       </td>
                       <td align="center" @click.stop="openFormDetalleCuota(item)">
                         <template>
                           <v-icon>
                             mdi-magnify
                           </v-icon>
                         </template>                    
                       </td>
                     </tr>
                   </tbody>     
               </v-simple-table>
             </v-col>
           </v-row>
           <v-row no-gutters>
             <v-col md="12" xl="8" offset-xl="2">
               <v-simple-table>
                   <colgroup>
                     <col span="1" style="width:500px">
                     <col span="1" style="width:150px">
                     <col span="1" style="width:250px">
                     <col span="1" style="width:90px">
                   </colgroup>                  
                   <tbody>
                     <tr class="bodyCuotas ">
                       <td>
                         <span class="white--text ml-8">DEUDA</span> 
                       </td>
                       <td>
                         <span>SUBTOTAL</span>
                       </td>
                       <td align="right"> 
                         <span>$ {{ $formatNumber(totalVencidas) }}</span>                       
                       </td>
                       <td @click.stop="clickCheckDeuda">                  
                         <v-checkbox
                           :class="!registros.cuotasVencidas.length ? 'ocultar' : ''"
                           class="centerCheckbox"
                           :ripple="false"
                           color="primary"
                           v-model="checkDeuda" 
                           disabled                    
                         ></v-checkbox>     
                       </td>
                     </tr>
                   </tbody>
               </v-simple-table>
             </v-col>
           </v-row>
           <v-row class="table-deudas table-position" no-gutters>
             <v-col md="12" xl="8" offset-xl="2">
               <v-simple-table :height="heightVencidas" style="overflow:auto">
                 <colgroup>
                   <col span="1" style="width:500px">
                   <col span="1" style="width:150px">
                   <col span="1" style="width:240px">
                   <col span="1" style="width:90px">
                 </colgroup>               
                 <tbody @scroll.passive="handleScroll">
                   <template v-for="(item,k) in registros.cuotasVencidas">
                     <tr        
                       :key="k + 999999999"          
                       :class="checkMora ? 'colorMora' : '' "  
                       @click.stop="ClickExpandMora"
                     >
                       <template v-if="indexCuotaMora == k">
                         <td> 
                           <template>
                             <v-icon v-show="!expandMoraSn" class="mr-2">
                               mdi-chevron-down
                             </v-icon>
                             <v-icon v-show="expandMoraSn" class="mr-2">
                               mdi-chevron-up
                             </v-icon>                        
                              EN MORA
                           </template>                         
                         </td>
                         <td>
                           <span>SUBTOTAL</span>                        
                         </td>
                         <td align="right">
                           $ {{ $formatNumber(totalCuotasMora) }}
                         </td>         
                         <td @click.stop="alertaMora">
                           <v-checkbox
                             class="centerCheckboxMora"
                             :ripple="false"
                             color="primary"
                             v-model="checkMora"    
                             disabled                                        
                           ></v-checkbox>     
                         </td>      
                       </template> 
                     </tr> 
                     <tr
                       :key="k"
                       @click.stop="item.esMora ? clickRow(item, 'mora',k): clickRow(item,'vencidas',k)" 
                       :class="item.esMora && item.check ? 'colorMora' : !item.esMora && item.check ? 'colorCheck' : ''"            
                       name="fade"                         
                     >                
                       <template v-if="!item.esMora">                                     
                         <td colspan="2">
                           <span class="ml-8">
                             {{ item.periodo }}
                             <template v-if="tributo == 'COMERCIOS'">
                               / {{ item.tasa}}                        
                            </template>  
                           </span>
                         </td>
                         <td align="right">
                           <span>$ {{ $formatNumber(item.total) }}</span> 
                         </td>
                         <td align="center" @click.stop="openFormDetalleCuota(item)">
                           <template>
                             <v-icon>
                               mdi-magnify
                             </v-icon>
                           </template>                    
                         </td>                
                       </template> 
   
                       <template v-if="item.esMora && expandMoraSn">
                         <td colspan="2">
                           <span style="padding-left:110px">
                             {{ item.periodo }}
                            <template v-if="tributo == 'COMERCIOS'" class="ml-4">
                              / {{ item.tasa}}                        
                            </template>   
                           </span>
                         </td>
                         <td align="right">
                           $ {{ $formatNumber(item.total) }}
                           </td>
                         <td align="center" @click.stop="openFormDetalleCuota(item)">
                           <template>
                             <v-icon>
                               mdi-magnify
                             </v-icon>
                           </template>                    
                         </td>                  
                       </template> 
                     </tr>   
                   </template>
                 </tbody>  
               </v-simple-table>  
             </v-col>
           </v-row> 
          <v-img id="flecha" src="/img/flecha.gif" max-width="30"> </v-img>          
        </div>
      </v-container>

      <v-container fluid>
        <v-row justify="center" style="margin-top:0px">  
            <botonInicio style="display: inline;" class="ml-4 mr-4"/> 
            <botonVolver v-if="!esMora" style="display: inline;" class="ml-4 mr-4"/> 
            <botonVolver :prevent="true" v-if="esMora" @backPage="esMora = false" style="display: inline;" class="ml-4 mr-4"/>           
            <botonBoleta style="display: inline;" @Click="pedirFechaVencimiento" class="ml-4 mr-4" />
            <botonQr style="display: inline;" @Click="openMedioPago" class="ml-4 mr-4" />
        </v-row>
        <v-row>
          <v-col cols="12" style="height: 50px;"></v-col>
        </v-row>
      </v-container>
    </div>

    <pdfDialog v-model="showPdfDialog" ref="pdfDialog" />
    <dateDialog v-model="showDateDialog" ref="dateDialog" @ConfirmDate="ConfirmDate"/>
    <qrDialog v-model="showQrDialog" ref="qrDialog" />
    <detalleCuotaDialog v-model="showDetalleCuotaDialog" ref="detalleCuotaDialog"/>
    <mediosPagoDialog v-model="showMediosPagoDialog"  @setQr="setQr" ref="mediosPagoDialog" />
    <deudasLegalesDialog ref="deudasLegalesDialog"/>
    <bonificacionDialog ref="bonificacionDialog"/>
    <mensajeDialog ref="mensajeDialog"/>
    <mensajeCerrarDialog ref="mensajeCerrarDialog"/>
    <mensajeErrorDialog ref="mensajeErrorDialog"/>
    <mensajeAutomotorDialog ref="mensajeAutomotorDialog"/>
    <mensajeComercioDialog ref="mensajeComercioDialog"/>
    <mensajeMaxBoletaDialog ref="mensajeMaxBoletaDialog"/>
    <mensajeCertificadoLibreDeudaDialog ref="mensajeCertificadoLibreDeudaDialog"/>
  </div>
</template>

<script>  
import Constants from '../config.js'
import moment from 'moment'
import Vue from 'vue'
export default {
  components: {
    pdfDialog: () => import('../components/pdfDialog.vue'),
    mediosPagoDialog: () => import('../components/mediosPagoDialog.vue'),
    qrDialog: () => import('../components/qrDialog.vue'),
    detalleCuotaDialog: () => import('../components/detalleCuotaDialog.vue'),
    botonQr: () => import('../components/botonQr.vue'), 
    botonBoleta: () => import('../components/botonBoleta.vue'),
    botonInicio: () => import('../components/botonInicio.vue'),
    botonSelTodos: () => import('../components/botonSelTodo.vue'),
    botonDesSelTodos: () => import('../components/botonDesSelTodo.vue'),
    dateDialog: () => import('../components/dateDialog.vue'),
    deudasLegalesDialog: () => import('../components/deudasLegalesDialog.vue'),
    bonificacionDialog: () => import('../components/bonificacionDialog.vue'),
    autogestionPasoDos: () => import('../components/autogestionPasoDos.vue'),
    autogestionPasoTres: () => import('../components/autogestionPasoTres.vue'),
    mensajeAutomotorDialog: () => import('../components/mensajeAutomotorDialog.vue'),
    mensajeComercioDialog: () => import('../components/mensajeComercioDialog.vue'),
    mensajeMaxBoletaDialog: () => import('../components/mensajeMaxBoletaDialog.vue'),
    mensajeCertificadoLibreDeudaDialog: () => import('../components/mensajeCertificadoLibreDeudaDialog.vue'),
  }, 
  props: {
    tributo: {
      type: String,
      required: true,
    },
    cuenta: {
      type: String,
      required: true,
    },
  },    
  data () { return {   
    user_id : localStorage.getItem('userId'),
    heightRowBase: 48,
    heightVencidasBase: 896,
    checkAVencer: false,
    expandMoraSn: false,
    checkDeuda: false,  
    checkMora: false,
    checkSelecionarTodo: false,        
    tablaKey: 0,        
    itemsPerPage: -1,
    moraCheck: false,
    registros: {             
                 cuotasVencidas:[                   
                  { check:false,
                    total:0,
                  }
                 ],
                 cuotasAVencer:[
                  { check:false,
                    total:0,
                  }
                 ]
                },
    esMora: false,                
    url_pdf: '',
    url_qr: '',
    showPdfDialog: false,
    showDateDialog: false,
    showMediosPagoDialog: false,
    showQrDialog: false, 
    showDetalleCuotaDialog: false,  
    medioPago:'',
    selected: []
  }},
  mounted: async function () {
    await this.$store.dispatch('loadFecha')
    await this.getCuotas()
    this.eschucharScroll()     
  },    
  destroyed () {
    const scrollDeuda = document.querySelectorAll('.v-data-table__wrapper')
    scrollDeuda[1].removeEventListener('scroll', this.handleScroll)
  },
  watch : {
    esMora() {
      setTimeout(() => {
        this.iniciarScrollbar()        
      }, 600);
    }
  },	
  computed: {
    deudasLegales : function() {
      return this.registros.deudaLegales
    },
    indexCuotaMora: function() {
      return this.registros.cuotasVencidas.findIndex(e => e.esMora)
    },
    totalCuotasMora: function() {
      return parseFloat(this.registros.cuotasVencidas.filter(e =>e.esMora).reduce((a, b) => +a + +b.total, 0)).toFixed(2)
    },
    totalVencidas: function() {
      return parseFloat(this.registros.cuotasVencidas.reduce((a, b) => +a + +b.total, 0)).toFixed(2)
    },
    totalVencidasSeleccionadas: function() {      
      return this.registros.cuotasVencidas.filter(e => e.check).reduce((a, b) => +a + +b.total, 0).toFixed(2)
    },
    totalAVencerSeleccionadas: function() {      
      return this.registros.cuotasAVencer.filter(e => e.check).reduce((a, b) => +a + +b.total, 0).toFixed(2)
    },    
    totalAVencer: function() {
      return parseFloat(this.registros.cuotasAVencer.reduce((a, b) => +a + +b.total, 0)).toFixed(2)
    },    
    total: function () {
      return  (parseFloat(this.totalVencidas) + parseFloat(this.totalAVencer)).toFixed(2)
    },
    totalSeleccionada: function () {
      return (parseFloat(this.totalVencidasSeleccionadas) + parseFloat(this.totalAVencerSeleccionadas)).toFixed(2)
    },
    headersComputed: function () {
      return this.tributo === 'COMERCIOS' ? this.headersComercio : this.headers
    },
    totalAbonar: function () {
      if (this.registros.descuento !== 0 && this.totalVencidas === this.totalVencidasSeleccionadas) {
        return (parseFloat(this.totalSeleccionada) - parseFloat(this.registros.descuento))
      } else {
        return parseFloat(this.totalSeleccionada)
      }      
    },
    heightAVencer: function () {
      if(this.registros.cuotasAVencer.length === 0) return this.heightRowBase      
      return (this.heightRowBase * this.registros.cuotasAVencer.length)
    },
    heightVencidas: function () {
      return this.heightVencidasBase - this.heightAVencer
    }
  },
  methods: {
    forceRerender() {
      const CantCheckAVencer = this.registros.cuotasAVencer.reduce((suma, obj) => {
        if (obj.check) {
          return suma += 1
        }
        return suma
      },0) 

      const CantCheckVencidasMora = this.registros.cuotasVencidas.reduce((suma, obj) => {
        if (obj.isMora && obj.check) {
          return suma += 1
        }
        return suma
      },0)    
      
      const CantCheckVencidasNoMora = this.registros.cuotasVencidas.reduce((suma, obj) => {
        if (!obj.isMora && obj.check) {
          return suma += 1
        }
        return suma
      },0)         
      
      this.checkDeuda = (CantCheckVencidasMora + CantCheckVencidasNoMora == this.registros.cuotasVencidas.length) ? true : false 
      this.checkAVencer = (CantCheckAVencer == this.registros.cuotasAVencer.length) ? true : false      
      this.checkSelecionarTodo = (this.checkDeuda && this.checkAVencer) ? true : false
      this.tablaKey += 1;
    },     
    eschucharScroll : function () {
      const scrollDeuda = document.querySelector('.table-deudas .v-data-table__wrapper')
      this.iniciarScrollbar()
      scrollDeuda.addEventListener('scroll', this.handleScroll)
    },
    iniciarScrollbar: function() {
      const scrollDeuda = document.querySelector('.table-deudas .v-data-table__wrapper')
      var hasVerticalScrollbarDeuda = scrollDeuda.scrollHeight > scrollDeuda.clientHeight
      if (hasVerticalScrollbarDeuda) {
        document.getElementById("flecha").style.opacity = "1"
      }      
    },
      subTitleTabla : function () {
        var mensaje = '';
        switch (this.tributo) {
          case 'ALCVP Y SV':
            mensaje = 'Alumbrado, Limpieza y Conservación de la vía pública';
            break;  
          case 'RODADOS':  
            mensaje = 'Patente Motovehículo';
            break; 
          case 'VEHICULOS':  
            mensaje = 'Patente Automotor';
            break;
          case 'COMERCIOS':
            mensaje = 'Comercio / Industria';
            break;    
          case 'PILETAS':
            mensaje = 'DNI Pileta';
            break;                    
          case 'CEMENTERIO': 
            mensaje = 'Cementerio';
            break;
          default:   
          break;    
      }
      return 'Cuenta Nº ' + this.cuenta + ' - ' + mensaje
    },    
    getCuotas: async function () {
      var url = '';
      switch (this.tributo) {
        case 'ALCVP Y SV':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/ABL'
            break; 
        case 'RODADOS':            
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta+ '/RODADOS'
            break; 
        case 'COMERCIOS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/COMERCIOS'
            break; 
        case 'PILETAS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/PILETAS'
            break; 
        case 'CEMENTERIO':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/CEMENTERIO'
            break; 
        case 'VEHICULOS':
          url = '/SAT_WS/rest/cuotas/cuenta/' + this.cuenta + '/VEHICULOS'
            break; 
      }

      await this.$store.dispatch('axiosMod/getData', { url: url }).then((response) => { 
        this.registros = response
          this.agregarPropiedadCheck()    
      }).catch(() => { this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"]) })      

      // await this.VerificarDeudaSn(this.registros)
      await this.verificarDeudasLegales(this.registros)
      await this.VerificarModeloAutomovil(this.registros)
      if (this.tributo == 'VEHICULOS' || this.tributo == 'RODADOS') {
        await this.$refs.mensajeCertificadoLibreDeudaDialog.open().then(() => {})
      }  

      if (this.tributo == 'COMERCIOS') {
        await this.$refs.mensajeComercioDialog.open().then(() => {})
      }        
      // await this.verificarBonificacion(this.registros)                
    },
    pedirFechaVencimiento : async function () {
      await this.generarSelected()
      if (!this.hayCheck()) {
        this.$refs.mensajeCerrarDialog.open('Seleccioná al menos una cuota.')                
        return
      }    

      const maxBoletaSn = this.totalAbonar > parseFloat(Constants.maxMontoBoleta)
      
      if (maxBoletaSn) {       
        await this.$refs.mensajeMaxBoletaDialog.open()
        return 
      } 

      this.showDateDialog = true     
      this.$refs.dateDialog.setForm(moment(this.$store.getters.getFecha).format('YYYY-MM-DD'),this.tributo,this.cuenta,this.selected,this.totalAbonar,this.subTitleTabla())      

    },
    ConfirmDate: function (date) {
      this.getPdf(moment(date).format())
    },
    getPdf: async function (date) {
      var url = ''
      switch (this.tributo) {
        case 'ALCVP Y SV':        
            url = '/SAT_WS/rest/recibo/' + this.cuenta + '/ABL/totem/usuario/' + this.user_id
          break;  
        case 'RODADOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/RODADOS/totem/usuario/' + this.user_id
          break; 
        case 'VEHICULOS':  
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/VEHICULOS/totem/usuario/' + this.user_id
          break;    
        case 'COMERCIOS': 
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/COMERCIOS/totem/usuario/' + this.user_id
          break; 
        case 'PILETAS':
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/PILETAS/totem/usuario/' + this.user_id
          break;            
        case 'CEMENTERIO': 
          url = '/SAT_WS/rest/recibo/' + this.cuenta + '/CEMENTERIO/totem/usuario/' + this.user_id
          break;                              
        default:
          break;
      }

      await this.$store.dispatch('axiosMod/postData', { url: url, dataForm: { fecha: date, cuotas: this.selected } }).then((response) => { 
          this.url_pdf = response.url
          this.showPdfDialog = true
          this.$store.commit('setbackground', 'background3')         
          this.$refs.pdfDialog.setForm(this.url_pdf)            
      }).catch(() => {
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
      })     
    },
    openMedioPago: async function () {  
      await this.generarSelected()
       if (!this.hayCheck()) {
         this.$refs.mensajeCerrarDialog.open('Seleccioná al menos una cuota.')                
         return
       }  
       this.showMediosPagoDialog = true   
       this.$store.commit('setbackground', 'background3')                     
    },   
    setQr: async function (medioPago) {
       var url = ''
      switch (this.tributo) {
        case 'ALCVP Y SV':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/ABL/totem/usuario/' + this.user_id
          break;  
        case 'RODADOS':  
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/RODADOS/totem/usuario/' + this.user_id
          break; 
        case 'VEHICULOS':  
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/VEHICULOS/totem/usuario/' + this.user_id
          break;
        case 'COMERCIOS':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/COMERCIOS/totem/usuario/' + this.user_id
          break;    
        case 'PILETAS':
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/PILETAS/totem/usuario/' + this.user_id
          break;                    
        case 'CEMENTERIO': 
          url = '/SAT_WS/rest/pago/' + this.cuenta + '/CEMENTERIO/totem/usuario/' + this.user_id           
          break;
        default:          
          break;
      }         
      await this.$store.dispatch('axiosMod/postData', { url: url, dataForm: { medioPago: medioPago, cuotas: this.selected } }).then((response) => { 
          this.showQrDialog = true
          console.log(response)
          this.$refs.qrDialog.setForm(response.url)             
      }).catch(() => {
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
      })            
    },
    hayCheck: function () {
      return this.selected.length
    },
    clickRow: function (item, tabla, index) {
      if (tabla === 'vencidas') {
        item.check = !item.check     
        Vue.set(this.registros.cuotasVencidas, index, this.registros.cuotasVencidas[index])
      } else if(tabla === 'aVencer')  {
        item.check = !item.check     
        Vue.set(this.registros.cuotasAVencer, index, this.registros.cuotasAVencer[index])
      } else if(tabla === 'mora') {
        this.alertaMora()
      }   
      this.forceRerender()    
    },     

    alertaMora: function () {
      this.checkMora = !this.checkMora
      if (!this.moraCheck) {
        this.$refs.mensajeCerrarDialog.open('Los periodos marcados corresponden a una deuda en \n proceso de ser reclamada judicialmente. Deberá ser \n abonada en su totalidad.')                
      }      
      this.clickCheckMora()
    },
    ClickExpandMora: function () {
      this.expandMoraSn = !this.expandMoraSn
    },

    clickCheckAVencer: function () {
      this.checkAVencer = !this.checkAVencer
      this.registros.cuotasAVencer.forEach(function (item,index) {
        if (!item.esMora) {
          item.check = this.checkAVencer
          Vue.set(this.registros.cuotasAVencer, index, this.registros.cuotasAVencer[index])
        }
      }.bind(this))      
      this.forceRerender()     
    },   

    clickCheckDeuda: function () {
      this.checkDeuda = !this.checkDeuda
      this.checkMora = this.checkDeuda
      this.registros.cuotasVencidas.forEach(function (item,index) {
        item.check = this.checkDeuda
        this.moraCheck = item.check
        Vue.set(this.registros.cuotasVencidas, index, this.registros.cuotasVencidas[index])       
      }.bind(this))    
      this.forceRerender()     
   },    

    clickCheckMora: function() {
      this.registros.cuotasVencidas.forEach(function (item,index) {
        if (item.esMora) {
          item.check = !item.check
          this.moraCheck = item.check
          Vue.set(this.registros.cuotasVencidas, index, this.registros.cuotasVencidas[index])
        }
      }.bind(this))
      this.forceRerender()    
    },    

    clickCheckSelTodo: function () {
      this.checkSelecionarTodo = !this.checkSelecionarTodo

      this.registros.cuotasAVencer.forEach(function (item,index) {
          item.check = this.checkSelecionarTodo
          Vue.set(this.registros.cuotasAVencer, index, this.registros.cuotasAVencer[index])
      }.bind(this))         
      
      this.registros.cuotasVencidas.forEach(function (item,index) {
          item.check = this.checkSelecionarTodo
          this.moraCheck = item.check
          Vue.set(this.registros.cuotasVencidas, index, this.registros.cuotasVencidas[index])     
      }.bind(this))    

      this.checkAVencer = this.checkSelecionarTodo
      this.checkMora = this.checkSelecionarTodo
      this.checkDeuda = this.checkSelecionarTodo
    },    
    agregarPropiedadCheck: function () {
      this.registros.cuotasVencidas.forEach(function (item) {
        Object.defineProperty(item, 'check', { value: false, enumerable: true, writable:true, configurable: true})
      });
      this.registros.cuotasAVencer.forEach(function (item) {
        Object.defineProperty(item, 'check', { value: false, enumerable: true, writable:true, configurable: true})
      });           
    },
    openFormDetalleCuota: function(item) {
      this.showDetalleCuotaDialog = true
      this.$refs.detalleCuotaDialog.setForm(item)   
    },
    generarSelected: async function () {
        this.selected = []
        this.registros.cuotasVencidas.forEach(function (item) {
          if (item.check) {
            this.selected.push(item)
          }
      }.bind(this));
      this.registros.cuotasAVencer.forEach(function (item) {
        if (item.check) {
          this.selected.push(item)
        }          
      }.bind(this));
    },
    VerificarDeudaSn: async function (registros) {
      if (!registros.cuotasVencidas.length && !registros.cuotasAVencer.length) {
        await this.$refs.mensajeDialog.open('La cuenta seleccionada no posee deudas.').then(() => { })
      }      
    },
    verificarDeudasLegales : async function (registros) {
      console.log('verificar deudasLegales', registros.deudaLegales)
      if (registros.deudaLegales) {
        await this.$refs.deudasLegalesDialog.open().then(() => {})
      }      
    },
    VerificarModeloAutomovil: async function (registros) {
      console.log('data = ', registros)
      if (this.tributo === 'VEHICULOS') {
        if (parseInt(registros.modelo) >= 1994 && parseInt(registros.modelo) <= 1999) {
          await this.$refs.mensajeAutomotorDialog.open().then(() => { })
        }
      }
    },
    verificarBonificacion : async function (registros) {
      if (typeof registros.mensaje !== 'undefined') {
        await this.$refs.bonificacionDialog.open(registros.mensaje).then(() => { })
      }
    },    
    addColumn: async function () {
      if (this.tributo === 'COMERCIOS') {
        await this.headers.splice(1,0, { text: 'Tasa', align: 'start', value: 'tasa'},)
      }
    },
    handleScroll (event) {
      var flecha = 'flecha'
      var scrollTop = event.target.scrollTop
      var offsetHeight = event.target.offsetHeight
      var scrollHeight = event.target.scrollHeight
      var scrollBottom = scrollHeight - offsetHeight
      console.log(scrollBottom)
      if (scrollTop === scrollBottom) {
         document.getElementById(flecha).style.opacity = "0";
      } else {
         document.getElementById(flecha).style.opacity = "1";
      }     
    }
  },
}
</script>

<style>

  .ocultar {
    visibility:hidden !important;
  }

  .sinDescuento {
    text-decoration:line-through;
    text-decoration-thickness: 2px;
  }
 
  .centerCheckbox, .centerCheckboxMora {
    padding-left: 16px !important; 
    padding-left: 16px !important;
  } 

  .tablas {
    position: relative !important; 
  /* border: 1px solid #586EFD !important;*/
  }

  .theme--light .v-data-table .titulosTotales {
    background-color: rgb(29, 11, 255) !important;
  }

  .theme--light .v-data-table .colorMora {
    background-color: #f7c98b  !important
  }

  .theme--light .v-data-table .bodyCuotas {
    background-color: rgb(153, 153, 153) !important;
  }

  .theme--light .v-data-table .colorCheck {
    background-color: #f7c98b !important;
  }

  #flecha {
    overflow: hidden;
    position:absolute;
    bottom: -5px !important;
    left:48.5%;
    opacity: 0;
  }

  .subTitleTabla {
    font-size:18px !important;
  }
  .conDeudaLegales {
    visibility: visible;
  }

  .sinDeudaLegales {
    visibility: hidden;
  }

/*   ., . td {
    padding-left: 44px !important;
  } */

  tbody td {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: rgb(0, 0, 0);
  } 

  .v-data-table-header th {
    padding: 0 0 0 10px !important;
    font-size: 16px !important;
  }

  .titulosCuotasMora span, .bodyCuotas span {
    color: black;
    font-size: 16px !important;
    font-weight: bold !important
  }

  .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s
    }
  .fade-enter, .fade-leave-to  {
      opacity: 0
    }

  /* scroll */ 
  ::-webkit-scrollbar {
    width: 0px;
  }
  /* width */
  /*
  ::-webkit-scrollbar {
    width: 20px;
  }
  */
  /* Track */
  /*
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  */ 
  /* Handle */
  /*
  ::-webkit-scrollbar-thumb {
    background: #586EFD; 
    border-radius: 10px;
  }
  */
  /* Handle on hover */
  /*
  ::-webkit-scrollbar-thumb:hover {
    background: #586EFD;
  }
  */

</style>
